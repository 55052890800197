body {
  left: 0;
  top: 0;
  background-color: #6dd6f9;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color:#282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

header {
  margin: auto;
  background-color:#fbfbfb
}

.navbar h2 {
  font-size: 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  border-bottom: solid;
  border-width: 2px;
  border-color: rgb(193, 193, 193);
  padding: 0px 20px;
}

nav ul {
  display: flex;
  list-style-type: none;
  gap: 35px;
  align-items: center;
  
  
}

nav a{
  color: black;
  text-decoration: none;
}

nav a:visited{
  color: black;
}

nav button {
  width: 100px;
  height: 30px;
  font-size: 14px;
  border-radius: 20px;
  border-style: none;
  cursor: pointer;
  background-color: #25c6fc;
  color: white;
}

nav button:hover {
  background-color:#008dbc;
}

section {
  margin: auto;
  max-width: 90vw;
  background-color: #6dd6f9;
  padding-bottom: 100px;
}

/* Footer Styling */
footer {
  height: 40vh;
  background-color: #ffffff;
  border-top: solid;
  border-width: 2px;
  border-color: rgb(193, 193, 193);
}

footer h3 {
  font-weight: 300;
  text-align: center;
  font-size: 30px;
}

.footerBtns {
  margin: auto;
  margin-top: 25vh;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerBtns button {
  width: 150px;
  cursor: pointer;
  background: none;
  border-style: none;
  font-size: large;
  text-align: center;
}

/* Index Page */

.imageheader {
  display: flex;
  background-color: #ebebeb;
  align-items: center;
  justify-content: center;
  height: 40vh;
}

.indexRow:after {
  content: "";
  display: table;
  clear: both;
}

.indexColumn {
  float:left;
  width: 48%;
  margin-left: 10px;
  margin-right: 10px;
}

.aboutTab {
  margin-top: 50px;
  height: 40px;
  background-color: #31b7e4;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: rgb(193, 193, 193);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  text-align: center;
  color: white;
}

.aboutTab h2 {
  padding-top: 4px;
}

.aboutInfo {
  height: 500px;
  background-color: white;
}

.logoDiv{
  display: flex;
  align-items: center;
  gap: 2px;
}

/* Pricing Table Page */

.pricingHeader {
  text-align: center;
  font-size: 40px;
  color: white;
}

.column {
  float:left;
  width: 33%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column h2 {
  margin-top: 6px;
  text-align: center;
}

.planHead {
  display: flex;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ebebeb;
  border-radius: 10px;
  margin: 40px 30px -10px 30px;
  z-index: -1;
  background-color: #31b7e4;
  color: white;
}

.planHead .icon {
  margin-top: 8px;
}

.planInfo {
  height: 60px;
}

.bold {
  font-weight: bold;
}

.pricingContents {
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color:#d1d1d1;
  padding: 0px 10px 0px 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
}

.checkIcon {
  margin-right: 20px;
  float: right;
}

.priceBox {
  background-color: #efefef;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ebebeb;
  padding-left: 10px;
}

.priceBox .price {
  font-weight: bolder;
  font-size: large;
  margin-bottom: 0;
}

.priceBox .priceAlt {
  margin-top: 0;
  padding-top: 0;
  font-size: x-small;
}



.btnDivide {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  border-top: #ebebeb;
  border-top: solid;
  border-width: 1px;
  bottom: 0;
}

.pricingContents button {
  font-size: medium;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  border-style: none;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  background-color: #37cdff;
  color: white;
  cursor: pointer;
  
}

/* Call Demo Page */

.callHeading {
  text-align: center;
  font-size: 40px;
  color: white;
}

.callHeader {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 600px;
}

.callMain {
  padding: 3em;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: white;
}

.callMain h2 {
  border-bottom: 1px solid black;
}

.callHeader label {
  text-align: left;
  font-size: 1.25em;
  color: #777776;
  display: block;
}

.callHeader button {
  margin-bottom: 10px;
}

.left-column,
.center-column,
.right-column {
  width: 30%;
  min-width: 16em;
  margin: 0 1.5em;
  text-align: center;
}

/* Left Column */
#client-name {
  text-align: left;
  margin-bottom: 1em;
  font-family: "Helvetica Light", Helvetica, sans-serif;
  font-size: 1.25em;
  color: #777776;
}

select {
  width: 300px;
  height: 60px;
  margin-bottom: 10px;
}

/* Center Column */
input {
  font-family: Helvetica-LightOblique, Helvetica, sans-serif;
  font-style: oblique;
  font-size: 1em;
  width: 100%;
  height: 2.5em;
  padding: 0;
  display: block;
  margin: 10px 0;
}

div#volume-indicators {
  padding: 10px;
  margin-top: 20px;
  width: 500px;
  text-align: left;
}

div#volume-indicators > div {
  display: block;
  height: 20px;
  width: 0;
}

/* Right Column */
.right-column {
  padding: 0 1.5em;
}

#log {
  text-align: left;
  border: 1px solid #686865;
  padding: 10px;
  height: 9.5em;
  overflow-y: scroll;
}

.log-entry {
  color: #686865;
  font-family: "Share Tech Mono", "Courier New", Courier, fixed-width;
  font-size: 1.25em;
  line-height: 1.25em;
  margin-left: 1em;
  text-indent: -1.25em;
  width: 90%;
}

/* Other Styles */
.hide {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

button:disabled {
  cursor: not-allowed;
}

.big-button {

  display: block;
  width: 100%;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

/* Login Page */
.loginForm {
  min-width: 18em;
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.smallButton {
  font-size: 0.8em;
}

.inputText {
  font-size: 1.3em;
  width: 100%
}

.loginForm button {
  margin: 0.5em;
}